import { navigate } from "gatsby"
import React, { useEffect } from "react"

const PeConference = () => {
  useEffect(() => {
    try {
      const seed = Date.now()
      console.log("Seed:", seed)

      const destination =
        seed % 2 === 0
          ? "/healthcare/data-driven-growth"
          : "/healthcare/investment-insights"
      console.log("Destination:", destination)

      navigate(destination)
    } catch (error) {
      console.error("Error navigating:", error)
    }
  }, [])

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  )
}

export default PeConference
